import { imageUrl, PT } from "~/utils/products";

export const productImages = (sku: string, type: string): string[] => {
  switch (type) {
    case PT.MOULDING:
      return [
        `${imageUrl(sku, PT.MOULDING, 1)}`,
        `${imageUrl(sku, PT.MOULDING, 2)}`,
        `${imageUrl(sku, PT.MOULDING, 3)}`,
        `${imageUrl(sku, PT.MOULDING, 4)}`,
        `${imageUrl(sku, PT.MOULDING, 5)}`,
      ];
    case PT.PHOTOFRAME:
      return [
        `${imageUrl(sku, PT.PHOTOFRAME, 1)}`,
        `${imageUrl(sku, PT.PHOTOFRAME, 2)}`,
        `${imageUrl(sku, PT.MOULDING, 5)}`,
        `${imageUrl(sku, PT.MOULDING, 4)}`,
      ];
    case PT.GALLERYFRAME:
      return [
        `${imageUrl(sku, PT.GALLERYFRAME, 1)}`,
        `${imageUrl(sku, PT.GALLERYFRAME, 2)}`,
        `${imageUrl(sku, PT.MOULDING, 5)}`,
        `${imageUrl(sku, PT.MOULDING, 4)}`,
      ];
    default:
      return [
        `${imageUrl(sku, PT.MOULDING, 1)}`,
        `${imageUrl(sku, PT.MOULDING, 2)}`,
        `${imageUrl(sku, PT.MOULDING, 3)}`,
        `${imageUrl(sku, PT.MOULDING, 4)}`,
        `${imageUrl(sku, PT.MOULDING, 5)}`,
      ];
  }
};
