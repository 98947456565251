import {
  type Component,
  type JSXElement,
  Show,
  For,
  createSignal,
} from "solid-js";
import CDialog from "@corvu/dialog";
import { Icon } from "solid-heroicons";
import { arrowsPointingOut, xMark } from "solid-heroicons/outline";
import { Product } from "~/services/roma-api/products/types";
import { PT, PTLabels } from "~/utils/products";
import { Img } from "../image";
import { productImages } from "./product-page/utils";

type ProductImageModalProps = {
  product: Product;
  type: string;
  trigger?: JSXElement;
};

export const ProductImageModal: Component<ProductImageModalProps> = (props) => {
  const ModalImageContent: Component<ProductImageModalProps> = (props) => {
    const [activeImage, setActiveImage] = createSignal(0);
    const displayType = () => {
      switch (props.type) {
        case PT.PHOTOFRAME:
        case PT.GALLERYFRAME:
        case PT.MOULDING:
          return props.type;
        default:
          return PT.MOULDING;
      }
    };
    const [activeType, setActiveType] = createSignal(displayType());
    const sku = props.product.SKU;
    const types = props.product.AvailableAs?.filter(
      (type) => type === "photoFrame" || type === "galleryFrame"
    );

    return (
      <>
        <div class="border-b border-gray-300 flex justify-between pb-2">
          <div class="flex items-center gap-2">
            <CDialog.Label class="text-base sm:text-2xl">
              {props.product.SKU}
            </CDialog.Label>
            <Show when={types.length > 0}>
              <button
                onClick={() => setActiveType(PT.MOULDING)}
                class={`ml-4 rounded-md px-2 py-1 text-xs sm:text-sm flex items-center justify-center active:translate-y-0.5 border  ${
                  activeType() === PT.MOULDING
                    ? "bg-roma-blue text-white border-roma-blue "
                    : "bg-roma-grey border-gray-300"
                }`}
              >
                {PTLabels[PT.MOULDING]}
              </button>
              <For each={types}>
                {(type) => (
                  <button
                    onClick={() =>
                      setActiveType(type as PT.PHOTOFRAME | PT.GALLERYFRAME)
                    }
                    class={`rounded-md px-2 py-1 text-xs sm:text-sm flex items-center justify-center active:translate-y-0.5 border  ${
                      activeType() === type
                        ? "bg-roma-blue text-white border-roma-blue "
                        : "bg-roma-grey border-gray-300"
                    }`}
                  >
                    {PTLabels[type]}
                  </button>
                )}
              </For>
            </Show>
          </div>
          <CDialog.Close class="active:translate-y-0.5">
            <Icon path={xMark} class="w-6 h-6" stroke-width={3} />
          </CDialog.Close>
        </div>

        {/* MAIN IMAGE CONTAINER */}
        <div class="w-full  max-md:w-[80vw] md:w-[70vh] aspect-square flex items-center justify-center">
          <Img
            src={productImages(sku, activeType())[activeImage()]}
            width={800}
            height={800}
          />
        </div>

        {/* THUMBNAILS */}
        <div class=" flex justify-center gap-3  pt-2">
          <For each={productImages(sku, activeType())}>
            {(img, i) => (
              <button
                onClick={() => setActiveImage(i())}
                class={`aspect-square max-w-[100px] border ${
                  activeImage() === i()
                    ? "border-roma-blue"
                    : "border-roma-dark-grey"
                }`}
              >
                <Img src={img} width={100} height={100} />
              </button>
            )}
          </For>
        </div>
      </>
    );
  };

  return (
    <CDialog role="dialog">
      <CDialog.Trigger>
        <Show
          when={props.trigger}
          fallback={
            <div class="bg-white rounded-md border border-gray-300 flex items-center justify-center w-8  aspect-square active:translate-y-0.5">
              <Icon path={arrowsPointingOut} class="w-5 h-5" />
            </div>
          }
        >
          {props.trigger}
        </Show>
      </CDialog.Trigger>
      <CDialog.Portal>
        <CDialog.Overlay class="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm" />
        <CDialog.Content class="overflow-hidden fixed left-1/2 top-1/2 z-50  max-w-[80vh] -translate-x-1/2 -translate-y-1/2 rounded-lg  bg-white px-6 py-5 duration-200 corvu-open:animate-in corvu-open:fade-in-0 corvu-open:zoom-in-95 corvu-open:slide-in-from-left-1/2 corvu-open:slide-in-from-top-[60%] corvu-closed:animate-out corvu-closed:fade-out-0 corvu-closed:zoom-out-95 corvu-closed:slide-out-to-left-1/2 corvu-closed:slide-out-to-top-[60%] transition-all flex flex-col gap-2 text-roma-dark-grey ">
          <ModalImageContent {...props} />
        </CDialog.Content>
      </CDialog.Portal>
    </CDialog>
  );
};
